<template>
  <Politique></Politique>
</template>

<script>
import Politique from '../components/Politique.vue'

export default {
  name: 'Confidentialite',
  components:{
    Politique
  }
}
</script>

<style lang="scss" scoped>

</style>
