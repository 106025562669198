<template>
  <Competences></Competences>
</template>

<script>
import Competences from '@/components/Competences.vue'
export default {
  name: 'Qualites',
  components: {
    Competences,
  },
}
</script>
