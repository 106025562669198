<template>
  <NotFound></NotFound>
</template>

<script>
import NotFound from "../components/NotFound";

export default {
  name: 'Page404',
  components:{
    NotFound
  }
}
</script>
